<template>
  <v-layout row wrap>
    <v-flex shrink class="ml-3">
      <div class="font-weight-medium">From</div>
      <div>{{ dateTime.start | moment('LLLL') }}</div>
    </v-flex>
    <v-flex shrink class="mx-2">
      <v-divider vertical></v-divider>
    </v-flex>
    <v-flex shrink>
      <div class="font-weight-medium">To</div>
      <div>{{ dateTime.end | moment('LLLL') }}</div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'date-selector-view',
  props: ['dateTime']
};
</script>

<style scoped></style>
