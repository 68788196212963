import cellsimPortalService from '@/api/cellSim.js';

export default {
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
  getSims(params) {
    return cellsimPortalService
      .Api().get(`activation`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
  getOrders(params) {
    return cellsimPortalService
      .Api().get(`task`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getBillPayments(params) {
    return cellsimPortalService
      .Api().get(`billPayment`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getWallet(params) {
    return cellsimPortalService
      .Api().get(`customerWalletTransaction`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  
}