<template>
  <div class="text-right">
    <v-chip 
			v-if="customLabel"	
			class="pa-2"  
			color="grey lighten-2" 
			v-model="customLabel"
			close
      @click:close="close"
			@input="cancelCustomDate"
		>
      <v-avatar>
        <v-icon small>mdi-calendar-multiple-check</v-icon>
      </v-avatar>
      <span>{{ startDateDisplayNormal }}
        <template v-if="range">
          To  {{ endDateDisplayNormal }}
        </template>
      </span>
    </v-chip>
    <template v-if="justCustomDate && !customLabel">
      <!-- <v-text-field
        solo
        hide-details
        v-model="startDateDisplayNormal"
        :label="`${range ? 'Start Date' : label ? label : 'Date'}`"
        readonly
        :clearable="clearable"
      ></v-text-field> -->
      <v-chip class="pa-2" @click="customBox" color="white" close @input="cancelCustomDate">
        <v-avatar>
          <v-icon small>mdi-calendar-multiple-check</v-icon>
        </v-avatar>
        <span>pick a date</span>
      </v-chip>
    </template>
    <template v-else>
      <div v-if="showSelectedDate" class="text-right mb-1">
        {{ start.date | moment('MM/DD/YYYY') }} -
        {{ end.date | moment('MM/DD/YYYY') }}
      </div>

      <v-select v-if="!customLabel" outlined dense hide-details item-text="name" :items="items" return-object
        v-model="selected" solo ref="selectDropdown">
        <template v-slot:prepend-item>
          <!-- <v-divider class="mt-2"></v-divider> -->
          <v-list-item ripple @click="customBox">
            <v-list-item-action>
              <v-icon>mdi-calendar-multiple-check</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Custom Date</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <template slot="item" slot-scope="{ item }">
          <span>{{ item.name }}</span>
        </template>
        <template v-slot:append-item>
          <v-divider class="mt-2"></v-divider>
          <v-list-item ripple @click="customBox">
            <v-list-item-action>
              <v-icon>mdi-calendar-multiple-check</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Custom Date</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </template>

    <v-dialog v-model="custom" width="500" persistent>
      <v-card>
				<v-card-title>
					<h3 class="subheading font-weight-regular">Custom Date</h3>
					<v-spacer></v-spacer>
					<v-btn icon flat @click.stop.prevent="cancel">
						<v-icon small>close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider></v-divider>

        <v-container grid-list-lg>
          <v-layout row wrap justify-center>
            <!-- START -->
            <v-flex shrink>
              <span>From</span>
              <v-menu ref="startMenu" v-model="startMenu" :close-on-content-click="false"
                :return-value.sync="startDateDisplay" transition="scale-transition" offset-y full-width>
                <template  v-slot:activator="{ on }">
                  <v-text-field v-on="on" outlined dense hide-details v-model="startDateDisplayNormal"
                    :label="`${range ? 'Start Date' : label ? label : 'Date'}`" readonly :clearable="clearable">
                  </v-text-field>
                </template>
                <v-card>
                  <v-tabs v-model="startActive" right slider-color="black">
                    <v-tab ripple>
                      <v-icon>mdi-calendar</v-icon>
                    </v-tab>
                    <v-tab v-if="!hideTime" ripple>
                      <v-icon>mdi-clock-outline</v-icon>
                    </v-tab>
										<v-tab-item>
											<v-date-picker color="primary" class="tile elevation-0" v-model="startDate" scrollable
												v-show="!start.selectTime" :max="endDate">
											</v-date-picker>
										</v-tab-item>
										<v-tab-item v-if="!hideTime">
											<v-time-picker v-model="start.time" color="primary" class="tile elevation-0">
											</v-time-picker>
										</v-tab-item>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click.stop.prevent="startMenu = false">Cancel</v-btn>
                    <v-btn color="primary" @click.stop.prevent="
                      $refs.startMenu.save(startDateDisplay)
                    ">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-flex>

            <!-- END -->
            <v-flex shrink v-if="range">
              <span>To</span>
              <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false"
                :return-value.sync="endDateDisplay" lazy transition="scale-transition" offset-y full-width>
                <template  v-slot:activator="{ on }">
                  <v-text-field v-on="on" outlined dense hide-details v-model="endDateDisplayNormal"
                    :label="`${endlabel ? endlabel : 'End Date'}`" readonly :clearable="clearable"></v-text-field>
                </template>
                <v-card>
                  <v-tabs v-model="endActive" right slider-color="black">
                    <v-tab ripple>
                      <v-icon>mdi-calendar</v-icon>
                    </v-tab>
                    <v-tab v-if="!hideTime" ripple>
                      <v-icon>mdi-clock-outline</v-icon>
                    </v-tab>
                    <v-tab-item>
                      <v-date-picker class="tile elevation-0" v-model="endDate" scrollable v-show="!end.selectTime"
                        :min="startDate" :max="noFuture ? $moment().format('YYYY-MM-DD') : null">
                      </v-date-picker>
                    </v-tab-item>
                    <v-tab-item v-if="!hideTime">
                      <v-time-picker class="tile elevation-0" v-model="end.time">
                      </v-time-picker>
                    </v-tab-item>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click.stop.prevent="close">Cancel</v-btn>
                    <v-btn color="primary" @click.stop.prevent="$refs.endMenu.save(endDateDisplay)">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-flex>
          </v-layout>

          <v-divider></v-divider>

          <v-layout row wrap justify-center align-center>
            <v-flex shrink xs6>
              <span>Weeks</span>
              <v-select hide-details solo :items="weeks">
                <template v-slot:item="{ item, index }">
                  <v-list-item ripple :key="index" @click.prevent.stop="customDate(item)">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.start | moment('MM/DD') }} - {{ item.end | moment('MM/DD') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-flex>
            <v-flex shrink xs6>
              <span>Days</span>
              <v-select hide-details solo :items="days">
                <template v-slot:item="{ item, index }">
                  <v-list-item ripple :key="index" @click.prevent.stop="customDate(item)">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.start | moment('dddd') }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.start | moment('MM/DD/YYYY') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" @click.stop.prevent="submitDate" :disabled="!startDate">
            Go
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'date-time-picker',
  props: [
    'showSelectedDate',
    'label',
    'endlabel',
    'range',
    'hideTime',
    'showIcon',
    'showEndIcon',
    'clearable',
    'initStartDate',
    'initEndDate',
    'noFuture',
    'autoSubmit',
    'defaultRange',
    'justCustomDate',
    'reportView',
  ],
  data() {
    return {
      selectedWeek: null,
      selectedDay: null,
      weeks: [],
      days: [],
      bottomNav: null,
      startActive: 0,
      endActive: 0,
      customLabel: false,
      custom: false,
      selected: {
        id: 'today',
        name: 'Today',
        start: this.$moment().startOf('day').toISOString(),
        end: this.$moment().endOf('day').toISOString(),
      },
      startMenu: false,
      start: {
        date: null,
        time: null,
        selectTime: false,
      },
      endMenu: false,
      Integration: null,
      end: {
        date: null,
        time: null,
        selectTime: false,
      },
    };
  },
  created() {
    this.loadWeeks();
    this.loadDays();
  },
  mounted() {
    if (this.defaultRange !== 'undefined') {
      const range = this.items.filter(
        (element) => element.id === this.defaultRange,
      );
      if (range.length > 0) this.selected = range[0];
    } else {
      if (this.reportView) {
        // console.log('reportview', this.defaultRange);
        this.startDate = this.initStartDate;
        this.endDate = this.initEndDate;
        this.customLabel = true;
      }
    }
    if (this.initStartDate && !this.reportView) {
      this.start.date = this.initStartDate;
      this.start.time = !this.hideTime
        ? this.$moment(this.initStartDate).format('HH:mm')
        : null;
    }
    if (this.initEndDate && !this.reportView) {
      this.end.date = this.initEndDate;
      this.end.time = !this.hideTime
        ? this.$moment(this.initEndDate).format('HH:mm')
        : null;
    }
  },
  computed: {
    today() {
      return {
        id: 'today',
        name: 'Today',
        start: this.$moment().startOf('day').toISOString(),
        end: this.$moment().endOf('day').toISOString(),
      };
    },
    yesterday() {
      return {
        id: 'yesterday',
        name: 'Yesterday',
        start: this.$moment().subtract(1, 'days').startOf('days').toISOString(),
        end: this.$moment().subtract(1, 'days').endOf('day').toISOString(),
      };
    },
    thiredDay() {
      return {
        id: 'yesterday',
        name: 'Yesterday',
        start: this.$moment().subtract(1, 'days').startOf('days').toISOString(),
        end: this.$moment().subtract(1, 'days').endOf('day').toISOString(),
      };
    },
    items() {
      const list = [
        {
          id: 'today',
          name: 'Today',
          start: this.$moment().startOf('day').toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'yesterday',
          name: 'Yesterday',
          start: this.$moment()
            .subtract(1, 'days')
            .startOf('days')
            .toISOString(),
          end: this.$moment().subtract(1, 'days').endOf('day').toISOString(),
        },
        {
          id: 'thisWeek',
          name: 'This Week',
          start: this.$moment().startOf('isoWeek').toISOString(),
          end: this.$moment().endOf('isoWeek').toISOString(),
        },
        {
          id: 'thisWeekToDate',
          name: 'This Week To Date',
          start: this.$moment().startOf('isoWeek').toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'lastWeek',
          name: 'Last Week',
          start: this.$moment()
            .subtract(1, 'week')
            .startOf('isoWeek')
            .toISOString(),
          end: this.$moment()
            .subtract(1, 'week')
            .endOf('isoWeek')
            .toISOString(),
        },
        {
          id: 'lastWeekToDate',
          name: 'Last Week To Date',
          start: this.$moment()
            .subtract(1, 'week')
            .startOf('isoWeek')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'thisMonth',
          name: 'This Month',
          start: this.$moment().startOf('month').toISOString(),
          end: this.$moment().endOf('month').toISOString(),
        },
        {
          id: 'thisMonthToDate',
          name: 'This Month To Date',
          start: this.$moment().startOf('month').toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'lastMonth',
          name: 'Last Month',
          start: this.$moment()
            .subtract(1, 'month')
            .startOf('month')
            .toISOString(),
          end: this.$moment().subtract(1, 'month').endOf('month').toISOString(),
        },
        {
          id: 'lastMonthToDate',
          name: 'Last Month To Date',
          start: this.$moment()
            .subtract(1, 'month')
            .startOf('month')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'thisQuarter',
          name: 'This Quarter',
          start: this.$moment().startOf('quarter').toISOString(),
          end: this.$moment().endOf('quarter').toISOString(),
        },
        {
          id: 'thisQuarterToDate',
          name: 'This Quarter To Date',
          start: this.$moment().startOf('quarter').toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'lastQuarter',
          name: 'Last Quarter',
          start: this.$moment()
            .subtract(1, 'quarter')
            .startOf('quarter')
            .toISOString(),
          end: this.$moment()
            .subtract(1, 'quarter')
            .endOf('quarter')
            .toISOString(),
        },
        {
          id: 'lastQuarterToDate',
          name: 'Last Quarter To Date',
          start: this.$moment()
            .subtract(1, 'quarter')
            .startOf('quarter')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'thisYear',
          name: 'This Year',
          start: this.$moment().startOf('year').toISOString(),
          end: this.$moment().endOf('year').toISOString(),
        },
        {
          id: 'thisYearToDate',
          name: 'This Year To Date',
          start: this.$moment().startOf('year').toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'thisYearToLastMonth',
          name: 'This Year To Last Month',
          start: this.$moment().startOf('year').toISOString(),
          end: this.$moment().subtract(1, 'month').endOf('month').toISOString(),
        },
        {
          id: 'lastYear',
          name: 'Last Year',
          start: this.$moment()
            .subtract(1, 'year')
            .startOf('year')
            .toISOString(),
          end: this.$moment().subtract(1, 'year').endOf('year').toISOString(),
        },
        {
          id: 'lastYearToDate',
          name: 'Last Year To Date',
          start: this.$moment()
            .subtract(1, 'year')
            .startOf('year')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'since7DaysAgo',
          name: 'Since 7 Days Ago',
          start: this.$moment()
            .startOf('days')
            .subtract(7, 'days')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'since15DaysAgo',
          name: 'Since 15 Days Ago',
          start: this.$moment()
            .startOf('days')
            .subtract(15, 'days')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'since30DaysAgo',
          name: 'Since 30 Days Ago',
          start: this.$moment()
            .startOf('days')
            .subtract(30, 'days')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'since60DaysAgo',
          name: 'Since 60 Days Ago',
          start: this.$moment()
            .startOf('days')
            .subtract(60, 'days')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'since90DaysAgo',
          name: 'Since 90 Days Ago',
          start: this.$moment()
            .startOf('days')
            .subtract(90, 'days')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
        {
          id: 'since365DaysAgo',
          name: 'Since 365 Days Ago',
          start: this.$moment()
            .startOf('days')
            .subtract(365, 'days')
            .toISOString(),
          end: this.$moment().endOf('day').toISOString(),
        },
      ];




      return list;
    },
    startDate: {
      get() {
        return this.start.date
          ? `${this.start.date.format('YYYY-MM-DD')}`
          : null;
      },
      set(val) {
        if (!val) return (this.start.date = null);
        this.start.date = new this.$moment(val);

        if (this.start.time) {
          const time = this.start.time.split(':');
          this.start.date.set({ hour: time[0], minute: time[1] });
        }
      },
    },
    endDate: {
      get() {
        return this.end.date ? `${this.end.date.format('YYYY-MM-DD')} ` : null;
      },
      set(val) {
        if (!val) return (this.end.date = null);
        this.end.date = new this.$moment(val);

        if (this.end.time) {
          const time = this.end.time.split(':');
          this.end.date.set({ hour: time[0], minute: time[1] });
        }
      },
    },
    startDateDisplay: {
      get() {
        return this.start.date
          ? `${this.start.date.format('MM/DD/YYYY')} ${this.start.time ? this.start.time : ''
          }`
          : null;
      },
      set(val) {
        if (val) {
          const newDate = new Date(val).toISOString();
          this.startDate = this.$moment(newDate).format('YYYY-MM-DD');
        } else {
          this.startDate = null;
          this.start.time = null;
        }
        this.submit();
      },
    },
    endDateDisplay: {
      get() {
        return this.end.date
          ? `${this.end.date.format('MM/DD/YYYY')} ${this.end.time ? this.end.time : ''
          }`
          : null;
      },
      set(val) {
        if (val) {
          const newDate = new Date(val).toISOString();
          this.endDate = this.$moment(newDate).format('YYYY-MM-DD');
        } else {
          this.endDate = null;
          this.end.time = null;
        }
        this.submit();
      },
    },
    startDateDisplayNormal: {
      get() {
        return this.start.date != null
          ? `${this.start.date.format('MM/DD/YYYY')} ${this.start.time
            ? this.$moment(
              `${this.start.date.format('YYYY-MM-DD')} ${this.start.time
              }`,
            ).format('hh:mm a')
            : ''
          }`
          : null;
      },
      set() {
        this.start.time = null;
        this.start.date = null;
        return this.submit();
      },
    },
    endDateDisplayNormal: {
      get() {
        return this.end.date
          ? `${this.end.date.format('MM/DD/YYYY')} ${this.end.time
            ? this.$moment(
              `${this.end.date.format('YYYY-MM-DD')} ${this.end.time}`,
            ).format('hh:mm a')
            : ''
          }`
          : null;
      },
      set() {
        this.end.time = null;
        this.end.date = null;
        return this.submit();
      },
    },
  },
  methods: {
		close() {
			this.customLabel = false
			this.startMenu = false
			this.endMenu = false
			this.cancel();
		},	
    loadWeeks() {
      this.weeks.push({
        start: this.autoWeek(1).start,
        end: this.autoWeek(1).end,
        name: 'One Week Ago',
      });
      this.weeks.push({
        start: this.autoWeek(2).start,
        end: this.autoWeek(2).end,
        name: 'Two Week Ago',
      });
      this.weeks.push({
        start: this.autoWeek(3).start,
        end: this.autoWeek(3).end,
        name: 'Three Week Ago',
      });
      this.weeks.push({
        start: this.autoWeek(4).start,
        end: this.autoWeek(4).end,
        name: 'Four Week Ago',
      });
    },
    loadDays() {
      for (let index = 1; index < 15; index++) {
        this.days.push({
          start: this.autoDay(index).start,
          end: this.autoDay(index).end,
          name: this.$moment(this.autoDay(index).start).format('dddd'),
        });
      }
      // console.log('days', this.days);
    },
    customDate(obj) {
      this.start.date = this.$moment(obj.start);
      this.end.date = this.$moment(obj.end);

      this.submit();
      this.submitDate();
    },
    autoDay(number) {
      const date = {
        start: this.$moment()
          .subtract(number, 'days')
          .startOf('days')
          .toISOString(),
        end: this.$moment().subtract(number, 'days').endOf('day').toISOString(),
      };
      return date;
    },
    autoWeek(number) {
      const date = {
        start: this.$moment()
          .subtract(number, 'week')
          .startOf('week')
          .toISOString(),
        end: this.$moment()
          .subtract(number, 'week')
          .endOf('week')
          .toISOString(),
      };
      return date;
    },
    cancelCustomDate() {
      if (this.selected) {
        this.startDate = this.selected.start;
        this.endDate = this.selected.end;
      }
      this.submit();
      this.$emit('submit');
    },
    submitDate() {
      this.$emit('submit');
      this.cancel();
      this.customLabel = true;
    },
    cancel() {
      console.log('cancel');
      this.custom = false;
      this.$emit('customDateSelected');
    },
    customBox() {
      console.log('customBox')
      this.custom = !this.custom;
      if (this.$refs.selectDropdown) {
        // console.log(this.$refs.selectDropdown);
        this.$refs.selectDropdown.blur();
      }
    },
    submit() {
      let date = null;
      if (this.range) {
        date = {
          range: this.selected.id,
          start: this.start.date
            ? this.start.time
              ? this.start.date.toISOString()
              : this.start.date.startOf('day').toISOString()
            : null,
          end: this.end.date
            ? this.end.time
              ? this.end.date.toISOString()
              : this.end.date.endOf('day').toISOString()
            : null,
        };
      } else if (this.start.date) {
        date = this.start.time
          ? this.start.date.toISOString()
          : this.start.date.startOf('day').toISOString();
      }
      this.start.selectTime = false;
      this.end.selectTime = false;
      if (date) this.$emit('input', date);
    },
  },
  watch: {
    // selectedWeek(val) {
    //   this.customDate(val);
    // },
    // custom(val) {
    //   this.startActive = parseInt(0);
    //   this.endActive = parseInt(0);
    // },
    // startMenu(val) {
    //   if (val) this.startActive = 0
    // },
    // startMenu(val) {
    //   if (val) this.endActive = 0
    // },
    selected: {
      handler(val) {
        this.startDate = val.start;
        // console.log('datepicker', val.start);
        // console.log('datepicker', val.end);
        this.endDate = val.end;

        this.submit();
        this.$emit('submit');
      },
      deep: true,
    },
    startDateDisplayNormal(val) {
      if (val === null) {
        this.end.date = null;
        this.end.time = null;
      }
    },
  },
};
</script>
